import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../pages/home';
import About from '../pages/about';
import Contact from '../pages/contactus/contact';
import Industrialconstruction from '../pages/civilconstruction';
import Resiconstruction from '../pages/resiconstruction';
import Aluminiumglass from '../pages/aluminiumglass';
import Fabrication from '../pages/fabrication';
import Packaging from '../pages/packaging';
import MechanicalItem from '../pages/mechanicalitems';
import Instrumentitem from '../pages/instrumentitem';
import Electricalitems from '../pages/electricalitems';
import Abrasivetools from '../pages/abrasivetools';
import Hydraulicspneumatics from '../pages/hydraulic';
import IThardware from '../pages/ithardware';
import Officefurniture from '../pages/officefurniture';
import Securtysaftey from '../pages/securitysafty';
import Quality from '../pages/quality';
import Career from '../pages/career';
import Indirectmaterial from '../pages/indirectmaterial';
import Aboutus from '../pages/aboutus';
import Electrical from '../pages/electrical';

const Index = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/industrial-construction" element={<Industrialconstruction />} />
      <Route path="/residential-construction" element={<Resiconstruction />} />
      <Route path="/Aluminium-Glass-Work" element={<Aluminiumglass />} />
      <Route path="/fabrication" element={<Fabrication />} />
      <Route path="/packaging" element={<Packaging />} />
      <Route path="/mechanical-item" element={<MechanicalItem />} />
      <Route path="/instrument-item" element={<Instrumentitem />} />
      <Route path="/electrical-item" element={<Electricalitems />} />
      <Route path="/abrasive-tools" element={<Abrasivetools />} />
      <Route path="/hydraulics-pneumatics" element={<Hydraulicspneumatics />} />
      <Route path="/it-hardware-appliance" element={<IThardware />} />
      <Route path="/Office-Furniture-Stationary" element={<Officefurniture />} />
      <Route path="/saftey-security" element={<Securtysaftey />} />
      <Route path="/quality" element={<Quality />} />
      <Route path="/career" element={<Career />} />
      <Route path="/indirect-material" element={<Indirectmaterial />} />
      <Route path="/about-us" element={<Aboutus />} />
      <Route path="/electrical" element={<Electrical />} />
    </Routes>
  );
};

export default Index;
