import React, { useEffect } from 'react'
import Electricals from '../../section/verticals/construction'

import overhead from '../../assets/images/HTLT (1).jpg'
import underground from '../../assets/images/HTLTUnderground Cables (2).jpg'
import panelandbreaker from '../../assets/images/HTLT Panels and Breakers (1).jpg'
import transformer from '../../assets/images/tranformer (2).jpg'
import dgset from '../../assets/images/dgsets (1).jpg'
import substation from '../../assets/images/substation (1).jpg'
import earthing from '../../assets/images/earthing (1).jpg'
import lighting from '../../assets/images/lighting (2).jpg'

const Index = () => {
    const link = '/contact-us'
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Electricals
                menuText='HT/LT Overhead Lines'
                leftText='Auto-Tech Industries designs, installs, and maintains HT and LT power lines, ensuring efficient, safe, and reliable energy distribution for industrial and residential areas, tailored to your specific needs.'
                rightimg={overhead}
                link={link}
            />
            <Electricals
                menuText='HT/LT Underground Cables'
                leftText='We specialize in HT and LT underground cable installation, offering trenching, laying, and terminations with advanced techniques for durable, efficient, and sustainable power transmission with minimal disruptions.'
                rightimg={underground}
                link={link}
            />
            <Electricals
                menuText='HT/LT Panels and Breakers'
                leftText='Our HT/LT panels ensure safe, efficient power control and protection. Customizable for diverse industries, they guarantee reliable installation, maintenance, and uninterrupted power management.'
                rightimg={panelandbreaker}
                link={link}
            />
            <Electricals
                menuText='Transformers'
                leftText='Auto-Tech Industries specializes in transformer installation, commissioning, and maintenance, delivering reliable, energy-efficient solutions for distribution and power transformers with a focus on quality and superior performance.'
                rightimg={transformer}
                link={link}
            />
            <Electricals
                menuText='DG Sets'
                leftText='We provide supply, installation, and maintenance of high-performance DG sets, ensuring reliable power backup for industrial, commercial, and residential needs during outages.'
                rightimg={dgset}
                link={link}
            />
            <Electricals
                menuText='11/22/33 KV Substations'
                leftText='We design, construct, and commission 11/22/33 KV high-voltage substations with advanced technologies, ensuring efficient, safe, and reliable power distribution that meets international standards for critical infrastructure.'
                rightimg={substation}
                link={link}
            />
            <Electricals
                menuText='Earthing Stations'
                leftText='Auto-Tech Industries offers reliable earthing solutions, ensuring safety and optimal electrical performance by effectively dispersing fault currents for equipment protection and enhanced operational reliability.'
                rightimg={earthing}
                link={link}
            />
            <Electricals
                menuText='Lighting and Ventilation'
                leftText='We provide energy-efficient lighting and ventilation systems, ensuring optimal illumination, air circulation, and comfort for industrial and commercial spaces while enhancing safety and sustainability.'
                rightimg={lighting}
                link={link}
            />
        </div>
    )
}

export default Index
