import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  // Link,
  Stack,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


import mainLogo from '../assets/images/mainlogo.png';
import navItembg from '../assets/images/nav-item-bg.png'
import linkedin from '../assets/images/linkedin.png';
import phonenum from '../assets/images/Phone.png';
import Mail from '../assets/images/Mail.png';

import Dropdown from 'react-bootstrap/Dropdown';
import './header.css'
import Nav from 'react-bootstrap/Nav';


// const pages = ['Products', 'Pricing', 'Blog', 'Products', 'Pricing', 'Blog'];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];


const Header = () => {

  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElSubmenu, setAnchorElSubmenu] = useState(null);

  const [anchorElCivil, setAnchorElCivil] = useState(null); // State for "Civil Construction" submenu
  const [anchorElIndirect, setAnchorElIndirect] = useState(null); // State for "Civil Construction" submenu


  const [constructionshow, setconstructionShow] = useState(false);
  const [aluminiumshow, setaluminiumShow] = useState(false);
  const [fabricationshow, setfabricationShow] = useState(false);
  const [packagingshow, setpackagingShow] = useState(false);
  const [indirectshow, setindirectShow] = useState(false);

  const handleconstructionMouseEnter = () => setconstructionShow(true);
  const handleconstructionMouseLeave = () => setconstructionShow(false);
  const handlealuminiumMouseEnter = () => setaluminiumShow(true);
  const handlealuminiumMouseLeave = () => setaluminiumShow(false);
  const handlefabricationMouseEnter = () => setfabricationShow(true);
  const handlefabricationMouseLeave = () => setfabricationShow(false);
  const handlepackagingMouseEnter = () => setpackagingShow(true);
  const handlepackagingMouseLeave = () => setpackagingShow(false);
  const handleindirectMouseEnter = () => setindirectShow(true);
  const handleindirectMouseLeave = () => setindirectShow(false);

  // const history = useHistory();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setAnchorElCivil(null);
    setAnchorElIndirect(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenSubmenu = (event, menuId) => {
    setAnchorElSubmenu(event.currentTarget);
  };
  const handleCloseSubmenu = () => {
    setAnchorElSubmenu(null);
  };

  const handleConstructionClick = () => {
    console.log('dd clicked');
    navigate('/industrial-construction');
  }

  const handleOpenCivilMenu = (event) => {
    setAnchorElCivil(event.currentTarget);
  };
  const handleCloseCivilMenu = () => {
    setAnchorElCivil(null);
  };
  const handleOpenIndirectMenu = (event) => {
    setAnchorElIndirect(event.currentTarget);
  };
  const handleCloseIndirectMenu = () => {
    setAnchorElIndirect(null);
  };

  return (
    <AppBar position="sticky" variant='outlined' sx={{
      backgroundColor: 'rgba(255, 255, 255, 0.9)', paddingRight: '0px'
    }}>
      <Container maxWidth={'xl'} >
        <Toolbar disableGutters >

          <Box sx={{ marginRight: 'auto', display: { xs: 'block', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="black"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu} component={Link} to="/">Home</MenuItem>
              <MenuItem onClick={handleOpenCivilMenu}>Civil Construction <ArrowDropDownIcon></ArrowDropDownIcon></MenuItem>
              <Menu
                id="civil-menu"
                anchorEl={anchorElCivil}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElCivil)}
                onClose={handleCloseCivilMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <MenuItem onClick={handleCloseNavMenu} component={Link} to="/industrial-construction">Industrial Construction</MenuItem>
                <MenuItem onClick={handleCloseNavMenu} component={Link} to="/residential-construction">Residential Construction</MenuItem>
              </Menu>
              <MenuItem onClick={handleCloseNavMenu} component={Link} to="/Aluminium-Glass-Work">Aluminium & Glass Work</MenuItem>
              <MenuItem onClick={handleCloseNavMenu} component={Link} to="/fabrication">Fabrication</MenuItem>
              <MenuItem onClick={handleOpenIndirectMenu}>Indirect Material <ArrowDropDownIcon></ArrowDropDownIcon></MenuItem>
              <Menu
                id="civil-menu"
                anchorEl={anchorElIndirect}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElIndirect)}
                onClose={handleCloseIndirectMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <MenuItem onClick={handleCloseNavMenu} component={Link} to="/mechanical-item">Mechanical Items</MenuItem>
                <MenuItem onClick={handleCloseNavMenu} component={Link} to="/instrument-item">Instrument Items</MenuItem>
                <MenuItem onClick={handleCloseNavMenu} component={Link} to="/electrical-item">Electrical Items</MenuItem>
                <MenuItem onClick={handleCloseNavMenu} component={Link} to="/abrasive-tools">Abrasives and Tools</MenuItem>
                <MenuItem onClick={handleCloseNavMenu} component={Link} to="/hydraulics-pneumatics">Hydraulics & Pneumatics</MenuItem>
                <MenuItem onClick={handleCloseNavMenu} component={Link} to="/it-hardware-appliance">IT Hardware and Appliances</MenuItem>
                <MenuItem onClick={handleCloseNavMenu} component={Link} to="/Office-Furniture-Stationary">Office Furniture & Stationary</MenuItem>
                <MenuItem onClick={handleCloseNavMenu} component={Link} to="/saftey-security">Safety & Security</MenuItem>
              </Menu>

              <MenuItem onClick={handleCloseNavMenu} component={Link} to="/packaging">Packaging</MenuItem>
              <MenuItem onClick={handleCloseNavMenu} component={Link} to="/electrical">Electrical</MenuItem>
              <MenuItem onClick={handleCloseNavMenu} component={Link} to="/quality">Quality</MenuItem>
            </Menu>
          </Box>

          <Box className='' sx={{ display: { xs: 'flex', md: 'none' } }}>
            {/* <IconButton> */}
            <a href="/" className=''><img src={mainLogo} alt="main logo" width={300} className='img-fluid' /></a>

            {/* </IconButton> */}
          </Box>



          {/* for desktop */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, backgroundColor: '' }}>
            {/* <IconButton > */}
            <a className='' href="/"><img className='' src={mainLogo} alt="main logo" width={200} /></a>

            {/* </IconButton> */}
          </Box>
          <Stack sx={{ width: '100%', marginLeft: 'auto' }}>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, marginLeft: 'auto' }}>
              <Stack direction="row" spacing={15}>
                <Box sx={{ backgroundColor: '', display: 'flex' }}>
                  <IconButton >
                    <img src={Mail} alt="email" width={30} />
                  </IconButton>
                  <Stack spacing={0} >
                    <Typography mb={-0.5} mt={0.5} fontSize={8}
                      sx={{ color: 'black', opacity: '0.6', fontSize: '12px', fontWeight: '400' }}
                    >Email-Id</Typography>
                    <Typography
                      sx={{ color: '#03435D', fontSize: '12px', fontWeight: '600' }}
                    >info@auto-tech.co.in</Typography>
                  </Stack>
                </Box>
                <Box sx={{ backgroundColor: '', display: 'flex' }}>
                  <IconButton>
                    <img src={phonenum} alt="email" width={30} />
                  </IconButton>
                  <Stack spacing={0} sx={{ margin: '0', fontWeight: '400' }}>
                    <Typography mb={-0.5} mt={0.5}
                      sx={{ color: 'black', opacity: '0.6', fontSize: '12px', fontFamily: '' }}
                    >Call for any help</Typography>
                    <Typography
                      sx={{ color: '#03435D', fontSize: '12px', fontWeight: '600' }}
                    >+91 7757080968</Typography>
                  </Stack>
                </Box>
                <Box sx={{ backgroundColor: '', display: 'flex' }}>


                </Box>
                <Box sx={{}}>
                  <a href="https://www.linkedin.com/company/auto-tech-industries-pune/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                    <IconButton>
                      <img src={linkedin} alt="linkedin" width={30} />
                    </IconButton>
                  </a>
                </Box>
              </Stack>
            </Box>

            <Box sx={{
              display: { xs: 'none', md: 'flex' }, backgroundImage: `url(${navItembg})`, backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }}>
              <Box sx={{
                display: { xs: 'none', md: 'flex' }, marginLeft: '6rem', flexGrow: '1', justifyContent: 'space-between'
              }}>
                {/* <Button onClick={handleCloseNavMenu} sx={{ my: 1, color: 'white', display: 'block', fontFamily: 'Squada One', opacity: '0.8', fontWeight: '400', fontSize: '18px', textTransform: 'none' }}>
                  Home
                </Button> */}
                <Nav.Link className='nav-item mt-3 ' href="/">Home</Nav.Link>
                {/* <Button onClick={handleCloseNavMenu} sx={{ my: 1, color: 'white', display: 'block', fontFamily: 'Squada One', opacity: '0.8', fontWeight: '400', fontSize: '18px', textTransform: 'none' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    Civil Construction
                    <ArrowDropDownIcon />
                  </Box>
                </Button> */}
                {/* onClick={handleConstructionClick} */}
                <Dropdown className='nav-item custom-dd'
                  onMouseEnter={handleconstructionMouseEnter}
                  onMouseLeave={handleconstructionMouseLeave}
                  show={constructionshow}>
                  <Dropdown.Toggle className='bg-transparent border border-0 mt-0' id="dropdown-basic">
                    Civil Construction
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/industrial-construction">Industrial</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/residential-construction">Residential</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <Button onClick={handleCloseNavMenu} sx={{ my: 1, color: 'white', display: 'block', fontFamily: 'Squada One', opacity: '0.8', fontWeight: '400', fontSize: '18px', textTransform: 'none' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    Aluminium & Glass Work
                    <ArrowDropDownIcon />
                  </Box>
                </Button> */}


                <Nav.Link className='nav-item mt-3 ' as={Link} to="/Aluminium-Glass-Work">Aluminium & Glass Work</Nav.Link>
                {/* <Dropdown className='nav-item' size='lg'
                  onMouseEnter={handlealuminiumMouseEnter}
                  onMouseLeave={handlealuminiumMouseLeave}
                  show={aluminiumshow}>
                  <Dropdown.Toggle className='bg-transparent border border-0 mt-0' id="dropdown-basic">
                    Aluminium & Glass Work
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/Aluminium-Glass-Work#section1">Aluminium Partition & Glass Work</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/Aluminium-Glass-Work#section2">Aluminium Sliding Window Work</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/Aluminium-Glass-Work#section3">False Ceiling Work</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/Aluminium-Glass-Work#section4">Window Curtain Work</Dropdown.Item>
                    {/* <Dropdown.Item href="#/action-2">Partition Work</Dropdown.Item> */}
                {/* </Dropdown.Menu> */}
                {/* </Dropdown> */}


                {/* <Button className='nav-item'>Aluminium & Glass Work</Button> */}

                <Nav.Link className='nav-item mt-3 me-3' as={Link} to="/fabrication">Fabrication</Nav.Link>
                {/* <Dropdown className='nav-item' size='lg'
                  onMouseEnter={handlefabricationMouseEnter}
                  onMouseLeave={handlefabricationMouseLeave}
                  show={fabricationshow}>
                  <Dropdown.Toggle className='bg-transparent border border-0 mt-0' id="dropdown-basic">
                    Fabrication
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/fabrication#section1">PEB</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/fabrication#section2">Repairing Work</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/fabrication#section3">New Trolley Making</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/fabrication#section4">Rolling Shutter Work</Dropdown.Item>
                    {/* <Dropdown.Item href="#/action-2">Partition Work</Dropdown.Item> */}
                {/* </Dropdown.Menu> */}
                {/* </Dropdown> */}

                {/* <Button onClick={handleCloseNavMenu} sx={{ my: 1, color: 'white', display: 'block', fontFamily: 'Squada One', opacity: '0.8', fontWeight: '400', fontSize: '18px', textTransform: 'none' }}>
                  Indirect Material
                </Button> */}

                {/* <Nav.Link className='nav-item mt-3 ' href="#home">Indirect Material</Nav.Link> */}
                <Dropdown className='nav-item' size='lg'
                  onMouseEnter={handleindirectMouseEnter}
                  onMouseLeave={handleindirectMouseLeave}
                  show={indirectshow}>
                  <Dropdown.Toggle className='bg-transparent border border-0 mt-0' id="dropdown-basic">
                    Indirect Material
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/mechanical-item">Mechanical Items</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/instrument-item">Instrument Items</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/electrical-item">Electrical Items</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/abrasive-tools">Abrasives and Tools</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/hydraulics-pneumatics">Hydraulics & Pneumatics</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/it-hardware-appliance">IT Hardware and Appliances</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/Office-Furniture-Stationary">Office Furniture & Stationary</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/saftey-security">Safety & Security</Dropdown.Item>
                    {/* <Dropdown.Item href="#/action-2">Partition Work</Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>

                {/* <Button onClick={handleCloseNavMenu} sx={{ my: 1, color: 'white', display: 'block', fontFamily: 'Squada One', opacity: '0.8', fontWeight: '400', fontSize: '18px', textTransform: 'none' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    Packaging
                    <ArrowDropDownIcon />
                  </Box>
                </Button> */}

                <Nav.Link className='nav-item mt-3 me-3' as={Link} to="/packaging">Packaging</Nav.Link>
                {/* <Dropdown className='nav-item' size='lg'
                  onMouseEnter={handlepackagingMouseEnter}
                  onMouseLeave={handlepackagingMouseLeave}
                  show={packagingshow}>
                  <Dropdown.Toggle className='bg-transparent border border-0 mt-0' id="dropdown-basic">
                    Packaging
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/packaging#section1">Angle board/Edge board</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/packaging#section2">Stretch Film</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/packaging#section3">Silica Gel</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/packaging#section4">Bopp Tape</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/packaging#section5">Wooden Pallet</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}


                {/* <Button onClick={handleCloseNavMenu} sx={{ my: 1, color: 'white', display: 'block', fontFamily: 'Squada One', opacity: '0.8', fontWeight: '400', fontSize: '18px', textTransform: 'none' }}>
                  Quality
                </Button> */}
                <Nav.Link className='nav-item mt-3 me-3' as={Link} to="/electrical">Electrical</Nav.Link>
                <Nav.Link className='nav-item mt-3 me-3' as={Link} to="/quality">Quality</Nav.Link>
              </Box>
            </Box>
          </Stack>


          {/* <Box sx={{ flexGrow: 0 }}> */}
          {/* <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip> */}
          {/* <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu> */}
          {/* </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
